._App {
  text-align: center;
}

.App {
    background-image:
        linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), /* overlay לבן */
        url('background.jpeg'); /* רקע לדף 2 */
    background-size: cover;
    background-attachment: fixed;
    background-position: center; /* Centers the image */
    min-height: 100vh;
}


.__App {
    background:
        linear-gradient(
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0)
        ), /* Overlay effect */
        radial-gradient(
            circle at center,
            #000000 0%, /* Black at the center */
            #333333 50%, /* Dark gray */
            #000000 100% /* Black towards the edges */
        ); /* Creates a smooth background */
    background-size: cover;
    background-attachment: fixed;
    background-position: center; /* Centers the gradient */
    min-height: 100vh;
}

body, html {
  overflow: auto;
  margin: 0;
  padding: 0;
  font-family: 'Assistant', sans-serif; /* Matches Material-UI theme */
}

/* הסתרת סרגלי גלילה בגוף הדף */
* {
  -ms-overflow-style: none;  /* עבור Internet Explorer */
  scrollbar-width: none;     /* עבור Firefox */
}

*::-webkit-scrollbar {
  display: none; /* עבור Webkit-based browsers (Chrome, Safari) */
}


html {
  font-size: 20px; /* בחר את הגודל הרצוי */
  font-family: 'Assistant', sans-serif;
}
._App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

._App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

._App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
