body {
  margin: 0;
  font-family: 'Assistant', sans-serif !important;
  direction: rtl;
}

code {
  font-family: 'Assistant', sans-serif !important;
}



.MuiTypography-body1 {
    font-family: 'Assistant', sans-serif !important;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
}