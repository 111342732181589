.pie-chart-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pie-chart-row {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.pie-chart-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chart-info-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 200px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px;
  background-color: #f9f9f9;
}

.info-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 8px;
}

.info-item > p {
  margin: 0;
}

.info-item > p:first-of-type {
  font-weight: bold;
}
